/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./home.scss";

const PrivacyPolicy = () => {
    const menuElements = [
        {
            name: "Home",
        },
        {
            name: "Product",
        },
        {
            name: "About Us",
        },
        {
            name: "Contact Us",
        },
    ];
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    return (
        <div className="bg-white">
            <div className="header">
                <nav className="navbar navbar-light navbar-expand-lg">
                    <Link className="navbar-brand navbar-brand d-flex mr-auto" to="/">
                        <img src="assets/img/logo.png" alt="Logo" />
                        <h4 className="brand-name">Emaan Reviver</h4>
                    </Link>
                    <div className="navbar-collapse collapse w-100" id="navbarSupportedContent">
                        <ul id="nav" className="navbar-nav ml-auto">
                            {menuElements.map((menu) => {
                                return (
                                    <li key={menu.name} className={`nav-item`}>
                                        <Link className="page-scroll" to="/">
                                            {menu.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
            <div className="d-flex flex-column privacy-policy">
                <p className="heading">Privacy Policy</p>
                <div className="content">
                    {/* <p>Last Updated: 2024-01-01</p> */}
                    <p>1. Introduction</p>
                    <div className="points-content">
                        <p>Welcome to Emaan Reviver.</p>
                        <p>
                            Emaan Reviver (“us”, “we”, or “our”) operates{" "}
                            <a href="https://www.emaanreviver.com" target="_blank" rel="noreferrer">
                                www.emaanreviver.com
                            </a>
                            (hereinafter referred to as “Service”). Our Privacy Policy governs your
                            visit to{" "}
                            <a href="https://www.emaanreviver.com" target="_blank" rel="noreferrer">
                                www.emaanreviver.com
                            </a>
                            , and explains how we collect, safeguard and disclose information that
                            results from your use of our Service.
                        </p>
                        <p>
                            We use your data to provide and improve Service. By using Service, you
                            agree to the collection and use of information in accordance with this
                            policy. Unless otherwise defined in this Privacy Policy, the terms used
                            in this Privacy Policy have the same meanings as in our Terms and
                            Conditions.
                        </p>
                        <p>
                            Our Terms and Conditions (“Terms”) govern all use of our Service and
                            together with the Privacy Policy constitutes your agreement with us
                            (“agreement”).
                        </p>
                    </div>
                    <p>2. Definitions</p>
                    <div className="points-content">
                        <p>
                            SERVICE means the{" "}
                            <a href="https://www.emaanreviver.com" target="_blank" rel="noreferrer">
                                www.emaanreviver.com
                            </a>{" "}
                            website operated by Emaan Reviver. PERSONAL DATA means data about a
                            living individual who can be identified from that data (or from those
                            and other information either in our possession or likely to come into
                            our possession).
                        </p>
                        <p>
                            USAGE DATA is data collected automatically either generated by the use
                            of Service or from Service infrastructure itself (for example, the
                            duration of a page visit). COOKIES are small files stored on your device
                            (computer or mobile device).
                        </p>
                        <p>
                            DATA CONTROLLER means a natural or legal person who (either alone or
                            jointly or in common with other persons) determines the purposes for
                            which and the manner in which any personal data are, or are to be,
                            processed. For the purpose of this Privacy Policy, we are a Data
                            Controller of your data.
                        </p>
                        <p>
                            DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person
                            who processes the data on behalf of the Data Controller. We may use the
                            services of various Service Providers in order to process your data more
                            effectively.
                        </p>
                        <p>
                            DATA SUBJECT is any living individual who is the subject of Personal
                            Data. THE USER is the individual using our Service. The User corresponds
                            to the Data Subject, who is the subject of Personal Data.
                        </p>
                        <p>
                            DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person
                            who processes the data on behalf of the Data Controller. We may use the
                            services of various Service Providers in order to process your data more
                            effectively.
                        </p>
                    </div>
                    <p>3. Information Collection and Use</p>
                    <p>
                        We collect several different types of information for various purposes to
                        provide and improve our Service to you.
                    </p>
                    <p>4. Types of Data Collected</p>
                    <p>Personal Data</p>
                    <div className="points-content">
                        <p>
                            While using our Service, we may ask you to provide us with certain
                            personally identifiable information that can be used to contact or
                            identify you (“Personal Data”).
                        </p>
                        <p>0.1. Email address</p>
                        <p>0.2. First name </p>
                        <p>0.3. Last name</p>
                        <p>0.4. Location</p>
                        <p>0.5. Gender</p>
                        <p>0.6. Date of birth</p>
                        <p>0.7. Cookies and Usage Data</p>
                    </div>
                    <div className="points-content">
                        <p>
                            As we launch our new Islamic app, we want to assure you that we take
                            your privacy seriously and will handle your personal information with
                            the utmost care and confidentiality. It is our top priority to maintain
                            transparency and earn your trust through our actions and words. This
                            information is collected to provide a personalised experience for our
                            users. We believe that honesty and integrity are fundamental to
                            conducting any business transaction, and this belief is rooted in our
                            Islamic faith. As the Quran says, "And give full measure when you
                            measure, and weigh with an even balance. That is the best [way] and best
                            in result" (17:35).The Messenger of Allah (ﷺ) said: “He is not one of us
                            who cheats” (
                            <a
                                href="https://sunnah.com/ibnmajah:2224"
                                target="_blank"
                                style={{ textDecoration: "underline" }}
                                rel="noreferrer"
                            >
                                Reference Here
                            </a>
                            )We believe that cheating and lying in business dealings goes against
                            the principles of Islam and it is a grave sin in the eyes of Allah. We
                            want to assure you that we will never engage in any such practices and
                            will uphold the highest standards of ethics and morality in all our
                            dealings.
                        </p>
                    </div>
                    <div className="points-content">
                        <p>
                            Our app accesses your selected image from your device to set it as your
                            profile picture. The selected image is used solely for the purpose of
                            displaying your profile picture within the app. We respect your privacy
                            and handle your data with utmost care. By selecting an image for your
                            profile picture, you provide us with affirmative consent to use the
                            selected image.
                        </p>
                    </div>
                    <div className="points-content">
                        <p>
                            Being an Islamic Company we make sure that we all are strictly adhered
                            to the ISLAMIC SHARIA in all circumstances In-Sha-Allah
                        </p>
                    </div>
                    {/* <p>Usage Data</p>
                    <div className="points-content">
                        <p>
                            We may also collect information that your browser sends whenever you
                            visit our Service or when you access Service by or through any device
                            (“Usage Data”). This Usage Data may include information such as your
                            computer’s Internet Protocol address (e.g. IP address), browser type,
                            browser version, the pages of our Service that you visit, the time and
                            date of your visit, the time spent on those pages, unique device
                            identifiers and other diagnostic data.
                        </p>
                        <p>
                            When you access Service with a device, this Usage Data may include
                            information such as the type of device you use, your device unique ID,
                            the IP address of your device, your device operating system, the type of
                            Internet browser you use, unique device identifiers and other diagnostic
                            data.
                        </p>
                    </div>
                    <p>Tracking Cookies Data</p>
                    <div className="points-content">
                        <p>
                            We use cookies and similar tracking technologies to track the activity
                            on our Service and we hold certain information.
                        </p>
                        <p>
                            Cookies are files with a small amount of data which may include an
                            anonymous unique identifier. Cookies are sent to your browser from a
                            website and stored on your device. Other tracking technologies are also
                            used such as beacons, tags and scripts to collect and track information
                            and to improve and analyse our Service.
                        </p>
                        <p>
                            You can instruct your browser to refuse all cookies or to indicate when
                            a cookie is being sent. However, if you do not accept cookies, you may
                            not be able to use some portions of our Service.
                        </p>
                        <p>Examples of Cookies we use:</p>
                        <p>0.1. Session Cookies: We use Session Cookies to operate our Service.</p>
                        <p>
                            0.2. Preference Cookies: We use Preference Cookies to remember your
                            preferences and various settings.
                        </p>
                        <p>0.3. Security Cookies: We use Security Cookies for security purposes.</p>
                        <p>
                            0.4. Advertising Cookies: Advertising Cookies are used to serve you with
                            advertisements that may be relevant to you and your interests.
                        </p>
                    </div>
                    <p>Other Data</p>
                    <p>
                        While using our Service, we may also collect the following information: sex,
                        age, date of birth, place of birth, passport details, citizenship,
                        registration at place of residence and actual address, telephone number
                        (work, mobile), details of documents on education, qualification,
                        professional training, employment agreements, NDA agreements, information on
                        bonuses and compensation, information on marital status, family members,
                        social security (or other taxpayer identification) number, office location
                        and other data.
                    </p> */}
                    <p>5. Use of Data</p>
                    <div className="points-content">
                        <p>Emaan Reviver uses the collected data for various purposes:</p>
                        <p>0.1. to provide and maintain our Service;</p>
                        <p>0.2. to notify you about changes to our Service;</p>
                        <p>
                            0.3. to allow you to participate in interactive features of our Service
                            when you choose to do so;
                        </p>
                        <p>0.4. to provide customer support;</p>
                        <p>
                            0.5. to gather analysis or valuable information so that we can improve
                            our Service;
                        </p>
                        <p>0.6. to monitor the usage of our Service;</p>
                        <p>0.7. to detect, prevent and address technical issues;</p>
                        <p>0.8. to fulfil any other purpose for which you provide it;</p>
                        <p>
                            0.9. to carry out our obligations and enforce our rights arising from
                            any contracts entered into between you and us, including for billing and
                            collection;
                        </p>
                        <p>
                            0.10. to provide you with notices about your account and/or
                            subscription, including expiration and renewal notices,
                            email-instructions, etc.;
                        </p>
                        <p>
                            0.11. to provide you with news, special offers and general information
                            about other goods, services and events which we offer that are similar
                            to those that you have already purchased or enquired about unless you
                            have opted not to receive such information;
                        </p>
                        <p>
                            0.12. in any other way we may describe when you provide the information;
                        </p>
                        <p>0.13. for any other purpose with your consent.</p>
                    </div>
                    <p>6. Retention of Data</p>
                    <div className="points-content">
                        <p>
                            We will retain your Personal Data only for as long as is necessary for
                            the purposes set out in this Privacy Policy. We will retain and use your
                            Personal Data to the extent necessary to comply with our legal
                            obligations (for example, if we are required to retain your data to
                            comply with applicable laws), resolve disputes, and enforce our legal
                            agreements and policies.
                        </p>
                        <p>
                            We will also retain Usage Data for internal analysis purposes. Usage
                            Data is generally retained for a shorter period, except when this data
                            is used to strengthen the security or to improve the functionality of
                            our Service, or we are legally obligated to retain this data for longer
                            time periods.
                        </p>
                    </div>
                    <p>7. Transfer of Data</p>
                    <div className="points-content">
                        <p>
                            Your information, including Personal Data, may be transferred to – and
                            maintained on – computers located outside of your state, province,
                            country or other governmental jurisdiction where the data protection
                            laws may differ from those of your jurisdiction.
                        </p>
                        <p>
                            If you are located outside Amazon Web Services and choose to provide
                            information to us, please note that we transfer the data, including
                            Personal Data, to Amazon Web Services and process it there.
                        </p>
                        <p>
                            Your consent to this Privacy Policy followed by your submission of such
                            information represents your agreement to that transfer.
                        </p>
                        <p>
                            Emaan Reviver will take all the steps reasonably necessary to ensure
                            that your data is treated securely and in accordance with this Privacy
                            Policy and no transfer of your Personal Data will take place to an
                            organisation or a country unless there are adequate controls in place
                            including the security of your data and other personal information.
                        </p>
                    </div>
                    <p>8. Disclosure of Data</p>
                    <div className="points-content">
                        <p>We may disclose personal information that we collect, or you provide:</p>
                        <p>0.1. Business Transaction.</p>
                        <p>
                            If we or our subsidiaries are involved in a merger, acquisition or asset
                            sale, your Personal Data may be transferred.
                        </p>
                        <p>0.2. Other cases. We may disclose your information also:</p>
                        <p>0.2.1. to our subsidiaries and affiliates;</p>
                        <p>
                            0.2.2. to contractors, service providers, and other third parties we use
                            to support our business;
                        </p>
                        <p>0.2.3. to fulfill the purpose for which you provide it;</p>
                        <p>
                            0.2.4. for the purpose of including your company's logo on our website;
                        </p>
                        <p>
                            0.2.5. for any other purpose disclosed by us when you provide the
                            information;
                        </p>
                        <p>0.2.6. with your consent in any other cases;</p>
                        <p>
                            0.2.7. if we believe disclosure is necessary or appropriate to protect
                            the rights, property, or safety of the Company, our customers, or
                            others.
                        </p>
                    </div>
                    <p>9. Security of Data</p>
                    <p>
                        The security of your data is important to us but remember that no method of
                        transmission over the Internet or method of electronic storage is 100%
                        secure. While we strive to use commercially acceptable means to protect your
                        Personal Data, we cannot guarantee its absolute security.
                    </p>
                    <p>
                        10. Your Data Protection Rights Under General Data Protection Regulation
                        (GDPR)
                    </p>
                    <div className="points-content">
                        <p>
                            If you are a resident of the European Union (EU) and European Economic
                            Area (EEA), you have certain data protection rights, covered by GDPR.
                        </p>
                        <p>
                            We aim to take reasonable steps to allow you to correct, amend, delete,
                            or limit the use of your Personal Data.
                        </p>
                        <p>
                            If you wish to be informed what Personal Data we hold about you and if
                            you want it to be removed from our systems, please email us at
                            <a
                                href="mailto:emaanreviver@gmail.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                emaanreviver@gmail.com
                            </a>
                            .
                        </p>
                        <p>
                            In certain circumstances, you have the following data protection rights:
                        </p>
                        <p>
                            0.1. the right to access, update or to delete the information we have on
                            you;
                        </p>
                        <p>
                            0.2. the right of rectification. You have the right to have your
                            information rectified if that information is inaccurate or incomplete;
                        </p>
                        <p>
                            0.3. the right to object. You have the right to object to our processing
                            of your Personal Data;
                        </p>
                        <p>
                            0.4. the right of restriction. You have the right to request that we
                            restrict the processing of your personal information;
                        </p>
                        <p>
                            0.5. the right to data portability. You have the right to be provided
                            with a copy of your Personal Data in a structured, machine-readable and
                            commonly used format;
                        </p>
                        <p>
                            0.6. the right to withdraw consent. You also have the right to withdraw
                            your consent at any time where we rely on your consent to process your
                            personal information;
                        </p>
                        <p>
                            Please note that we may ask you to verify your identity before
                            responding to such requests. Please note, we may not able to provide
                            Service without some necessary data.
                        </p>
                        <p>
                            You have the right to complain to a Data Protection Authority about our
                            collection and use of your Personal Data. For more information, please
                            contact your local data protection authority in the European Economic
                            Area (EEA).
                        </p>
                    </div>
                    <p>11. Analytics</p>
                    <p>
                        We may use third-party Service Providers to monitor and analyze the use of
                        our Service.
                    </p>
                    <p>12. CI/CD tools</p>
                    <p>
                        We may use third-party Service Providers to automate the development process
                        of our Service.
                    </p>
                    <p>13. Behavioral Remarketing</p>
                    <p>
                        We may use remarketing services to advertise on third party websites to you
                        after you visited our Service. We and our third-party vendors use cookies to
                        inform, optimise and serve ads based on your past visits to our Service.
                    </p>
                    <p>14. Payments</p>
                    <p>
                        We may accept donations In Shaa Allah. In that case, we use third-party
                        services for payment processing (e.g. payment processors). We will not store
                        or collect your payment card details. That information is provided directly
                        to our third-party payment processors whose use of your personal information
                        is governed by their Privacy Policy. These payment processors adhere to the
                        standards set by PCI-DSS as managed by the PCI Security Standards Council,
                        which is a joint effort of brands like Visa, Mastercard, American Express
                        and Discover. PCI-DSS requirements help ensure the secure handling of
                        payment information.
                    </p>
                    <p>15. Links to Other Sites</p>
                    <div className="points-content">
                        <p>
                            Our Service may contain links to other sites that are not operated by
                            us. If you click a third party link, you will be directed to that third
                            party's site. We strongly advise you to review the Privacy Policy of
                            every site you visit. We have no control over and assume no
                            responsibility for the content, products, payments, privacy policies or
                            practices of any third party sites or services.{" "}
                        </p>
                        <p>
                            For example, the outlined privacy policy has been made using
                            PolicyMaker.io, a free tool that helps create high-quality legal
                            documents. PolicyMaker's privacy policy generator is an easy-to-use tool
                            for creating a privacy policy for blog, website, e-commerce store or
                            mobile app.
                        </p>
                    </div>
                    <p>16. Parental Guidance for Children’s Use of Our Services</p>
                    <div className="points-content">
                        <p>
                            While our application is designed to provide Islamic content that is
                            suitable for all ages, we believe it is important for parents to play an
                            active role in monitoring their children’s online activities. Therefore,
                            we encourage parents to supervise their children while using our
                            services. Although our content does not have any restrictions for
                            children, parental guidance can help ensure a safe and beneficial
                            experience for them. We appreciate your understanding and cooperation in
                            this matter. As a reminder, while we strive to provide a safe
                            environment, the responsibility for any actions taken by children while
                            using our services ultimately lies with their parents or guardians.
                        </p>
                    </div>
                    <p>17. Changes to This Privacy Policy</p>
                    <p>
                        We may update our Privacy Policy from time to time. You are advised to
                        review this Privacy Policy periodically for any changes. Changes to this
                        Privacy Policy are effective when they are posted on this page.
                    </p>
                    <p>18. Contact Us</p>
                    <div className="points-content">
                        <p>
                            If you have any questions about this Privacy Policy, please contact us
                            by email:{" "}
                            <a
                                href="mailto:emaanreviver@gmail.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                emaanreviver@gmail.com
                            </a>
                        </p>
                    </div>
                    <div className="points-content">
                        <p>
                            This Privacy Policy was created for{" "}
                            <a href="https://www.emaanreviver.com" target="_blank" rel="noreferrer">
                                www.emaanreviver.com
                            </a>{" "}
                            by{" "}
                            <a href="https://policymaker.io/" target="_blank" rel="noreferrer">
                                PolicyMaker.io
                            </a>{" "}
                            on 2023-12-31.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PrivacyPolicy;
